import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import { height, width } from "../../../utilities/general";
import ProductionOrderList from "../List/ProductionOrderList";
import SearchBar from "../../presentational/SearchBar";
import Gap from "../../presentational/Gap";

const DispatchListModal = () => {
  const { dispatchListModalVisible, productionOrderSearchInput } =
    useStoreState((state) => state);

  const {
    setDispatchListModalVisibleAction,
    setProductionOrderSearchInputAction,
  } = useStoreActions((actions) => actions);

  return (
    <CustomModal
      isVisible={dispatchListModalVisible}
      onClose={() => {
        setDispatchListModalVisibleAction(false);
        setProductionOrderSearchInputAction("");
      }}
      title="Production Orders"
      style={{ width: width * 0.9, height: height * 0.9 }}
      width="50%"
      height="50%"
    >
      <SearchBar
        value={productionOrderSearchInput}
        onChangeText={(input) => setProductionOrderSearchInputAction(input)}
        placeholder="Search by Production Number, Description, Inventory ID"
      />
      <Gap />
      <ProductionOrderList isShownInModal={true} />
    </CustomModal>
  );
};

export default DispatchListModal;
