import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import { TouchableOpacity, View, Dimensions } from "react-native";
import Text from "../../presentational/Text";
import { FontAwesome, Ionicons } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";

const { height } = Dimensions.get("window");

function OperationsList(props) {
  const {
    workCenterDispatch,
    currentDispatchObject,
    operationsLoading,
    opNbr,
    user,
    operationListModalVisible,
    operationSearchInput,
  } = useStoreState((state) => state);

  const {
    selectOperation,
    setUpdatedDocNoteModalVisibleAction,
    upsertOperationFilesThunk,
    setUpdatedDocNote,
    setOperationListModalVisibleAction,
  } = useStoreActions((actions) => actions);

  const Element = ({ data, dispatch }) => (
    <TouchableOpacity onPress={() => selectOperation(data)}>
      <View
        style={{
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {dispatch.QtytoProduce > dispatch.QtyRemaining ? (
          <Ionicons
            name="checkmark-circle"
            size={24}
            color={
              dispatch.QtytoProduce > dispatch.QtyRemaining
                ? "#b2b200"
                : "green"
            }
            style={{
              left: 15,
              top: 10,
            }}
          />
        ) : (
          <Ionicons
            name="close-circle"
            size={24}
            color="red"
            style={{
              left: -15,
              top: 5,
            }}
          />
        )}

        <Text
          style={{
            fontSize: 25 - height / 150,
            textAlign: "center",
            alignSelf: "center",
            padding: 10,
            textDecorationLine: "underline",
            color: "blue",
          }}
        >
          {data}
        </Text>
      </View>
    </TouchableOpacity>
  );

  const isNorthstar = user.kioskConfig?.operationNotesAndFiles ?? false;

  const operationList = workCenterDispatch.filter((wcd) => {
    const formattedSearchInput = operationSearchInput.trim().toLowerCase();
    return (
      wcd.ProductionNbr === currentDispatchObject.ProductionNbr &&
      (wcd.OperationID.trim().includes(formattedSearchInput) ||
        wcd.OpsDesc.trim().toLowerCase().includes(formattedSearchInput))
    );
  });

  return (
    <List
      onPress={(data) => {
        selectOperation(data.OperationID);
        isNorthstar && setUpdatedDocNoteModalVisibleAction(true);
      }}
      selectItem={operationList.findIndex(
        (item) => item.OperationID == opNbr.trim()
      )}
      loading={operationsLoading}
      width={
        isNorthstar
          ? ["3%", "3%", "17%", "15%", "15%", "15%", "16%", "16%"]
          : ["16%", "17%", "17%", "16%", "16%", "16%"]
      }
      tableHead={
        isNorthstar
          ? [
              "",
              "",
              "Operation Number",
              "Operation Description",
              "Qty To Produce",
              "Qty Complete",
              "Qty Remaining",
              "Operation Status",
            ]
          : [
              "Operation Number",
              "Operation Description",
              "Qty To Produce",
              "Qty Complete",
              "Qty Remaining",
              "Operation Status",
            ]
      }
      tableRows={operationList.map((item) => {
        if (isNorthstar) {
          return [
            <TouchableOpacity
              style={{
                width: "100%",
                height: "100%",
              }}
              onPress={() => {
                setUpdatedDocNote(item.OperationNote);
                selectOperation(item.OperationID);
                operationListModalVisible &&
                  setOperationListModalVisibleAction(false);
                setUpdatedDocNoteModalVisibleAction(true);
              }}
            >
              <FontAwesome
                name="sticky-note"
                size={24}
                color={item.OperationNote === "" ? "gray" : "#988200"}
              />
            </TouchableOpacity>,
            <TouchableOpacity
              style={{
                width: "100%",
                height: "100%",
              }}
              onPress={async () => {
                selectOperation(item.OperationID);
                const document = await DocumentPicker.getDocumentAsync({
                  base64: true,
                  type: "*/*",
                });

                const b64 = document.assets?.[0].uri.split(",")[1];

                upsertOperationFilesThunk({
                  name: document.assets?.[0].name,
                  uri: b64,
                });
              }}
            >
              <FontAwesome name="paperclip" size={24} color="gray" />
            </TouchableOpacity>,
            <Element data={item.OperationID} dispatch={item} />,
            item.OpsDesc,
            parseInt(item.QtytoProduce),
            parseInt(item.QtyComplete),
            parseInt(item.QtyRemaining),
            item.OpsStatus,
          ];
        } else {
          return [
            <Element data={item.OperationID} dispatch={item} />,
            item.OpsDesc,
            parseInt(item.QtytoProduce),
            parseInt(item.QtyComplete),
            parseInt(item.QtyRemaining),
            item.OpsStatus,
          ];
        }
      })}
      {...props}
    />
  );
}

export default OperationsList;
