import loginModel from './model'
import loginthunks from './thunks';
import loginActions from './actions'
const loginStore = {
   ...loginModel,
   ...loginthunks,
   ...loginActions
}


export default loginStore;