import moment from "moment";

const defaultInputState = {
	BatchNbr: {
		value: "",
	},
	ControlQty: {
		value: 1,
	},
	Date: {
		value: moment().format(),
	},
	Details: [
		{
			note: {
				value: "",
			},
			LaborType: {
				value: "Direct", //laborType,
			},
			// LaborCode: {
			// 	value: "INDIRLAB", //laborType == 'Direct' ? 'DIRECT LAB ENG' : 'INDIR LAB ENG',
			// },
			ProductionNbr: {
				value: "", // selectedDispatch,
			},
			OperationNbr: {
				value: "", // opNbr,
			},
			EmployeeID: {
				value: "", //row[4],
			},
			LaborTime: {
				value: "", //row[6],
			},
			StartTime: {
				value: "", //row[7],
			},
			EndTime: {
				value: "", // row[8],
			},
			Shift: {
				value: "1",
			},
			INDocType: {
				value: "Receipt",
			},
			InventoryID: {
				value: "",
			},
			OrderType: {
				value: "WO",
			},
			QtyisScrap: {
				value: false,
			},
			// UOM: {
			// 	value: "EACH",
			// },
		},
	],
	Hold: {
		value: false,
	},
	PostPeriod: {
		value:
			moment().month() + 1 < 10
				? "0" +
				  parseInt(moment().month() + 1).toString() +
				  moment().year().toString()
				: parseInt(moment().month() + 1).toString() +
				  moment().year().toString(),
	},
	Status: {
		value: "Balanced",
	},
	TotalQty: {
		value: 1,
	},
	Date: {
		value: moment().format(),
	},
	Description: {
		value: "",
	},
};

const state = {
	labor: {
		laborModalVisible: false,
		laborType: "Direct",
		laborQty: 0,
		laborCode: "",
		laborPayload: [],
		laborTime: "",
		laborHours: "",
		laborMinutes: "",
		laborIndex: 0,
		laborTableChecked: false,
		laborTableIndex: 0,
		input: defaultInputState,
		laborLineItems: [],
		newLaborTime: "",

		areYouSureModalVisible: false,

		selectedLaborIndex: 0,
		laborNoteModalVisible: false,

		laborListModalActive: false,

		selectedItemIndex: null,
		laborDatePickerVisible: false,
	},
};

export { state as default, defaultInputState };
