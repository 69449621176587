import React, { useState, useLayoutEffect } from 'react';
import { Text, StyleSheet, Dimensions } from 'react-native';

const DynamicText = ({ text, fontSize, color }) => {
  const [dynamicFontSize, setDynamicFontSize] = useState(fontSize);

  useLayoutEffect(() => {
    const screenWidth = Dimensions.get('window').width;

    if (screenWidth >= 768 && screenWidth < 992) {
      setDynamicFontSize(fontSize * 1.25);
    } else if (screenWidth >= 992) {
      setDynamicFontSize(fontSize * 1.5);
    } else {
      setDynamicFontSize(fontSize);
    }
  }, [fontSize]);

  return (
    <Text
      style={[
        styles.dynamicText,
        { fontSize: dynamicFontSize },
        {
          color: color || 'black',
        },
      ]}
    >
      {text}
    </Text>
  );
};

const styles = StyleSheet.create({
  dynamicText: {
    // fontFamily: 'Helvetica Neue',
    // textAlign: 'center',
  },
});

export default DynamicText;
