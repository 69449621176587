import styled from "styled-components/native";
import { Platform, TouchableOpacity } from "react-native";
import Text from "../Text";
const T = styled.TouchableOpacity`
	display: table-cell;
	text-align: center;
	align-items: center;
	justify-content: center;
	align-content: center;
	font-size: ${Platform.OS === "web" ? "1rem" : "20px"};
	padding: ${Platform.OS === "web" ? ".5rem" : "10px"};
	border: ${(props) => (props.border ? "1px solid #dee2e6" : "1px")};
	width: ${(props) => props.width || "12.5%"};
`;
const V = styled.View`
	display: table-cell;
	text-align: center;
	align-items: center;
	justify-content: center;
	align-content: center;
	font-size: ${Platform.OS === "web" ? "1rem" : "20px"};
	padding: ${Platform.OS === "web" ? ".5rem" : "10px"};
	border: ${(props) => (props.border ? "1px solid #dee2e6" : "1px")};
	width: ${(props) => props.width || "12.5%"};
`;

const CellText = styled(Text)`
	font-size: ${Platform.OS === "web" ? "1rem" : "20px"};
`;

const Td = ({ onPress, children, ...props }) => {
	return (
		<>
			{props.index === 0 ? (
				<T
					activeOpacity={props.index === 0 ? 0 : 1}
					border={props.border}
					{...props}
					style={{
						backgroundColor: props.backgroundColor || "#f5f7f8",
					}}
					onPress={props.index === 0 ? onPress : undefined}
				>
					<CellText
						style={{
							color: "black",
							fontSize: props.fontSize || ".9rem",
						}}
					>
						{children}
					</CellText>
				</T>
			) : (
				<V
					activeOpacity={props.index === 0 ? 0 : 1}
					border={props.border}
					{...props}
					style={{
						backgroundColor: props.backgroundColor || "#f5f7f8",
					}}
					onPress={props.index === 0 ? onPress : undefined}
				>
					<CellText
						style={{
							color: "black",
							fontSize: props.fontSize || ".9rem",
							width: "20%",
						}}
					>
						{children}
					</CellText>
				</V>
			)}
		</>
	);
};

export default Td;
