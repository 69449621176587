import { createStore } from 'easy-peasy';
import loginStore from './login/index';
import workCenterStore from './workCenter/index';
import statusStore from './status/index';
import moveStore from './move/index';
import laborStore from './labor/index';
import taskStore from './task/index';
import docStore from './docs/index';

const model = {
  ...workCenterStore,
  ...loginStore,
  ...statusStore,
  ...moveStore,
  ...laborStore,
  ...taskStore,
  ...docStore,
};

const store = createStore(model);

export default store;
