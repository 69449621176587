const state = {
	docs: {
		docUrl: "",
		excelUrl: "",
		webViewActive: false,
		webViewURL: "",
		fileList: [],
		updatedDocNoteModalVisible: false,
	},
};

export default state;
