import React, { useEffect } from 'react';
import {
  Dimensions,
  Image,
  View,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { vhToDp } from '../../utilities/responsive';
import Card from '../presentational/Card.js';
import Gap from '../presentational/Gap';
import DynamicText from '../presentational/MyText';
import Center from '../presentational/Center/index.js';
import Page from '../presentational/Page';
import Button from '../presentational/Button';
import Icon from '../presentational/Icon';
import Input from '../presentational/Input';
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';

const AcumaticaLogin = ({ navigation }) => {
  const { acmUser, acmPassword, user } = useStoreState((state) => state);
  const { authenticateACM, setACMPassword, setACMUser } = useStoreActions(
    (actions) => actions
  );

  const Auth = async () => {
    let payload = {
      name: acmUser,
      password: acmPassword,
      token: user && user.token,
    };
    await authenticateACM(payload);
  };

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
    if (Platform.OS === 'web') {
      const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          Auth();
        }
      };
      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
  }, []);

  return (
    <Page>
      <Center.Screen>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card
            style={{
              padding: 10,
              maxWidth: 600,
            }}
          >
            <Image
              style={{
                width: '100%',
                height: vhToDp(8),
                resizeMode: 'contain',
                marginBottom: 20,
              }}
              source={{
                uri: `https://kioskbranding.blob.core.windows.net/images/${user.logo}`,
              }}
            />
            <View
              style={{
                alignSelf: 'center',
              }}
            >
              <DynamicText
                text="Acumatica Login"
                fontSize={20}
                color={'#61666b'}
              />
            </View>
            <Gap />

            <Input
              onChangeText={(email) => setACMUser(email)}
              value={acmUser}
              placeholder="Type username"
              inputMode="email"
              leftIcon={
                <Icon
                  Component={MaterialCommunityIcons}
                  name="account"
                  size={20}
                />
              }
            />

            <Gap />

            <Input
              onChangeText={(password) => setACMPassword(password)}
              value={acmPassword}
              placeholder="Type password"
              secureTextEntry
              leftIcon={<Icon Component={FontAwesome5} name="lock" size={20} />}
            />

            <Gap />

            <Button
              onPress={() => {
                Auth();
              }}
              fullWidth
            >
              Login
            </Button>
          </Card>
        </KeyboardAvoidingView>
      </Center.Screen>
    </Page>
  );
};
export default AcumaticaLogin;
