import { refreshTokenApiCall } from "../services";
import Storage from "./Storage";

function formatAuthHeader(token, type = "Bearer") {
  return `${type} ${token}`;
}

function axiosInterceptor(axios, store, key) {
  axios.interceptors.request.use(async (config) => {
    config.headers.Authorization = formatAuthHeader(await getAuthToken(key));
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.request.responseURL.includes("token/refresh/erp")) {
        await removeAuthToken(key);
        store.getActions().setUserIsLoggedIn(true);
        store.getActions().setAcumaticaIsLoggedIn(false);
      } else if (error.request.responseURL.includes("token/refresh")) {
        await removeAuthToken(key);
        store.getActions().setUserIsLoggedIn(false);
        store.getActions().setAcumaticaIsLoggedIn(false);
      } else if (error.response.status === 401) {
        // refresh token
        const newAccessTokenResponse = await refreshTokenApiCall(key);
        const newAccessToken = newAccessTokenResponse.data.accessToken;
        await setAuthToken(key, newAccessToken);
        error.config.headers.Authorization = formatAuthHeader(newAccessToken);
        return Promise.resolve(await axios.request(error.config));
      }

      return Promise.reject(error);
    }
  );
}

function getAuthToken(key) {
  return Storage.getItem(key);
}

async function setAuthToken(key, token) {
  await Storage.setItem(key, token);
}

async function removeAuthToken(key) {
  await Storage.removeItem(key);
}

export {
  formatAuthHeader,
  setAuthToken,
  axiosInterceptor,
  removeAuthToken,
  getAuthToken,
};
