import React, { useEffect } from "react";
import { Image, View, Platform, KeyboardAvoidingView } from "react-native";
import { useStoreActions, useStoreState } from "easy-peasy";
import { vhToDp } from "../../utilities/responsive";
import Card from "../presentational/Card.js";
import Gap from "../presentational/Gap";
import DynamicText from "../presentational/MyText";
import Center from "../presentational/Center/index.js";
import Page from "../presentational/Page";
import Button from "../presentational/Button";
import Icon from "../presentational/Icon";
import Input from "../presentational/Input";
import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";

const Login = ({ navigation }) => {
	const { user, email, password } = useStoreState((state) => state);
	const { authenticate, setPassword, setEmail, getAuthenticatedUserThunk } =
		useStoreActions((actions) => actions);
	const isFocused = useIsFocused();
	const Auth = async () => {
		let payload = {
			email: email,
			password: password,
		};

		await authenticate(payload);
	};

	useEffect(() => {
		if (isFocused) {
			getAuthenticatedUserThunk();

			navigation.setOptions({
				headerShown: false,
			});

			if (Platform.OS === "web") {
				const keyDownHandler = (event) => {
					if (event.key === "Enter") {
						event.preventDefault();
						Auth();
					}
				};

				document.addEventListener("keydown", keyDownHandler);

				return () => {
					document.removeEventListener("keydown", keyDownHandler);
				};
			}
		}
	}, [isFocused]);

	return (
		<Page>
			<Center.Screen>
				<KeyboardAvoidingView
					behavior={Platform.OS === "ios" ? "padding" : "height"}
					style={{
						width: "100%",
						height: "100%",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Card
						style={{
							maxWidth: 600,
							// height: 800,
							padding: 10,
						}}
					>
						<Image
							style={{
								width: "100%",
								height: vhToDp(8),
								resizeMode: "contain",
								marginBottom: 20,
							}}
							source={{
								uri: "https://kioskbranding.blob.core.windows.net/images/cfbsMESLogo.png",
							}}
						/>

						<View
							style={{
								alignSelf: "center",
							}}
						>
							<DynamicText
								text="Clients First MES App Registration"
								fontSize={20}
								color={"#61666b"}
							/>
						</View>
						<Gap />

						<Input
							onChangeText={(email) => setEmail(email)}
							value={email}
							placeholder="Type email"
							inputMode="email"
							leftIcon={
								<Icon
									Component={MaterialCommunityIcons}
									name="email"
									size={20}
								/>
							}
						/>

						<Gap />

						<Input
							onChangeText={(password) => setPassword(password)}
							value={password}
							placeholder="Type password"
							secureTextEntry
							leftIcon={<Icon Component={FontAwesome5} name="lock" size={20} />}
						/>

						<Gap />

						<Button
							onPress={() => {
								Auth();
							}}
							fullWidth
						>
							Login
						</Button>
					</Card>
				</KeyboardAvoidingView>
			</Center.Screen>
		</Page>
	);
};
export default Login;
