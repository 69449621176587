const loginModel = {
  email: "",
  password: "",
  acmUser: "",
  acmPassword: "",

  userIsLoggedIn: false,
  user: {},
  acumaticaIsLoggedIn: false,
  acumaticaLogin: {},

  logoutHeaders: "",
  apiBaseURL: process.env.REACT_BASE_URL + "/", //'https://cfbsmesbackend.azurewebsites.net/',
  // apiBaseURL: 'https://cfbsmesbackend-dev.azurewebsites.net/',
  // apiBaseURL: 'http://localhost:8080/',
  loginLoading: false,
};

export default loginModel;
