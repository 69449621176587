import List from "../../presentational/List";
import { useStoreState } from "easy-peasy";
import { View } from "react-native";
import Text from "../../presentational/Text";
import { Dimensions } from "react-native";

const { height } = Dimensions.get("window");

function MaterialList(props) {
  const { workCenterDispatch, selectedOperation } = useStoreState(
    (state) => state
  );

  const Element2 = ({ data, color }) => (
    <View
      style={{
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontSize: 22 - height / 150,
          textAlign: "center",
          alignSelf: "center",
          padding: 10,
          color: color,
          fontWeight: "bold",
        }}
      >
        {data}
      </Text>
    </View>
  );

  return (
    <List
      onPress={() => {
        console.log();
      }}
      tableHead={[
        "Inventory ID",
        "Description",
        "Qty Required",
        "Total Required",
        "UOM",
        "Qty Issued",
        "Qty Remaining",
        "Batch Size",
      ]}
      tableRows={workCenterDispatch
        .filter((wcd) => wcd.OperationID === selectedOperation?.OperationID)
        .map((item) => [
          <Element2
            data={item.MaterialInventory}
            color={item.MatQtyReq < item.MatQtyRemaining ? "green" : "red"}
          />,
          item.MaterialInventoryDesc,
          item.MatQtyReq,
          item.MatTotQtyReq,
          item.MatUOM,
          item.MatQtyPicked,
          item.MatQtyRemaining,
          item.BatchSize,
        ])}
      {...props}
    />
  );
}

export default MaterialList;
