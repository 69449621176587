import { thunk } from "easy-peasy";
import { PutTaskAPICall } from "../../services";

const thunks = {
  putTaskThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      await PutTaskAPICall({
        ...helpers.getState().task.input,
        Summary: {
          value: `${helpers.getState().selectedDispatch}-${
            helpers.getState().selectedOperation.OperationID
          }:  ${helpers.getState().task.taskSubject}`,
          RelatedEntityNoteID: {
            value: helpers.getState().selectedOperation.WONoteID,
          },
          RelatedEntityDescription: {
            value: helpers.getState().selectedDispatch,
          },
        },
      });
      actions.setTaskSubject("");
      actions.resetTaskInputAction();
      actions.setTaskModalVisible(false);
      actions.setAlertThunk({
        type: "success",
        message: "Task Created Successfully!",
      });
    } catch (error) {
      alert("Task was not created. Please try again.");

      actions.setAlertThunk({
        type: "error",
        message: "Error creating task",
      });
    }
    actions.setLoadingAction(false);
  }),
};
export default thunks;
