import { Platform } from 'react-native';
import styled from 'rn-css';

const Tr = styled.View`
  display: table-row;
  flex-direction: row;
  background-color: ${(props) => (props.striped ? '#D0E1FC' : '#fff')};

  &:hover {
    background-color: ${(props) => props.hover && '#D0E1FC'};
  }
  padding: ${Platform.OS === 'web' ? '.5rem' : '10px'};

`;

export default Tr;
