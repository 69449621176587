import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../../presentational/Button";
import Row from "../../presentational/Row";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import Gap from "../../presentational/Gap";
import moment from "moment";

export default function ProductionOrderNotesModal({
	confirmationPhrase,
	onSubmit,
}) {
	const {
		labor: { laborLineItems, selectedLaborIndex, laborNoteModalVisible },
		selectedDispatch,
		docs: { updatedDocNoteModalVisible, updatedDocNote },
	} = useStoreState((state) => state);

	const {
		setLaborNoteModalVisibleAction,
		setNewLaborNoteModalInputAction,
		processTimeThunk,
		setUpdatedDocNoteModalVisibleAction,
		setUpdatedDocNote,
		upsertOperationNoteThunk,
	} = useStoreActions((actions) => actions);

	return (
		<CustomModal
			style={{ width: 500 }}
			width="50%"
			height="50%"
			title="Notes"
			onClose={() => setUpdatedDocNoteModalVisibleAction(false)}
			isVisible={updatedDocNoteModalVisible}
		>
			<Col>
				<Input
					multiline
					numberOfLines={4}
					value={updatedDocNote}
					onChangeText={(text) => {
						setUpdatedDocNote(text);
					}}
				/>
			</Col>
			<Gap />
			<Row>
				<Col>
					<Button fullWidth onPress={() => upsertOperationNoteThunk()}>
						Update
					</Button>
				</Col>
			</Row>
		</CustomModal>
	);
}
