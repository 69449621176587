import styled from "styled-components/native";
import Td from "./Td";
import { Platform } from "react-native";

const Th = styled(Td)`
	width: ${(props) => props.width || "12.5%"};
	font-size: ${Platform.OS === "web" ? "15rem" : "20px"};
`;

export default Th;
