import AppNavigator from './src/navigation/AppNavigator';
import store from './src/store/index.js';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import { axios, acmAxios } from './src/services';
import { axiosInterceptor } from './src/utilities/api';
import { ERP_TOKEN_NAME, KIOSK_TOKEN_NAME } from './src/utilities/constants';

export default function App() {
  axiosInterceptor(axios, store, KIOSK_TOKEN_NAME);
  axiosInterceptor(acmAxios, store, ERP_TOKEN_NAME);
  return (
    <StoreProvider store={store}>
      <AppNavigator />
    </StoreProvider>
  );
}
