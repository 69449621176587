import moment from "moment";
import AreYouSureModal from "./AreYouSureModal";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  calculateNorthStarLaborTimeDifference,
  roundToNearest15Minutes,
} from "../../../utilities/time";

export default function ClockOutConfirmationModal() {
  const {
    user,
    labor: { laborLineItems, input, selectedLaborIndex },
  } = useStoreState((state) => state);

  const { processTimeThunk, setAlertThunk, setLaborModalVisible } =
    useStoreActions((actions) => actions);

  const isNorthStar = user.kioskConfig?.roundTimeTo15Minutes ?? false;

  return (
    <AreYouSureModal
      onSubmit={() => {
        const selectedLaborItem = laborLineItems[selectedLaborIndex];
        const {
          newStartTime,
          newEndTime,
          StartTime,
          EndTime,
          LaborTime,
          BatchNbr,
          AMTranNoteID,
          EmployeeID,
        } = selectedLaborItem;

        function processDetails(startTime, endTime, laborTime) {
          return {
            BatchNbr: { value: BatchNbr },
            Date: { value: moment().format("MM/DD/YYYY") },
            Description: { value: input.Description?.value || "" },
            Details: [
              {
                id: AMTranNoteID,
                EmployeeID: { value: EmployeeID },
                StartTime: {
                  value: startTime,
                },
                EndTime: {
                  value: endTime,
                },
                LaborTime: { value: laborTime },
              },
            ],
          };
        }

        function processDefaultDetails(startTime, endTime, laborTime) {
          const format = "h:mm A";
          return processDetails(
            startTime ? moment(startTime, format).format(format) : undefined,
            endTime ? moment(endTime, format).format(format) : undefined,
            laborTime
          );
        }

        function processNorthStarDetails(startTime, endTime, laborTime) {
          return processDetails(
            startTime ? roundToNearest15Minutes(startTime) : undefined,
            endTime ? roundToNearest15Minutes(endTime) : undefined,
            laborTime
          );
        }

        const processDetailsFn = isNorthStar
          ? processNorthStarDetails
          : processDefaultDetails;

        if (!StartTime && !EndTime) {
          processTimeThunk(
            processDetailsFn(
              newStartTime,
              newEndTime,
              selectedLaborItem.newLaborTime
            )
          );
        } else if (LaborTime == "00:01") {
          const formattedStartTime = moment(StartTime).format("h:mm A");
          const formattedEndTime = moment().format("h:mm A");

          const startTime = isNorthStar
            ? roundToNearest15Minutes(formattedStartTime)
            : formattedStartTime;
          const endTime = isNorthStar
            ? roundToNearest15Minutes(formattedEndTime)
            : formattedEndTime;
          const timeDifference = isNorthStar
            ? calculateNorthStarLaborTimeDifference(startTime, endTime)
            : moment
                .utc(
                  moment(endTime, "h:mm A").diff(moment(startTime, "h:mm A"))
                )
                .format("HH:mm");

          if (isNorthStar && timeDifference === "00:00") {
            setLaborModalVisible(false);
            setAlertThunk({
              type: "error",
              message: "Labor time cannot be 00:00.",
            });
          } else {
            processTimeThunk(
              processDetailsFn(startTime, endTime, timeDifference)
            );
          }
        } else if (newStartTime !== StartTime && newEndTime !== EndTime) {
          processTimeThunk(
            processDetailsFn(
              newStartTime,
              newEndTime,
              isNorthStar
                ? calculateNorthStarLaborTimeDifference(
                    newStartTime,
                    newEndTime
                  )
                : moment
                    .utc(
                      moment(newEndTime, "h:mm A").diff(
                        moment(newStartTime, "h:mm A")
                      )
                    )
                    .format("HH:mm")
            )
          );
        } else if (newStartTime == StartTime && newEndTime == EndTime) {
          processTimeThunk(
            processDetailsFn(null, moment().format("hh:mm A"), LaborTime)
          );
        }
      }}
    />
  );
}
