import { action } from "easy-peasy";

const loginActions = {
  setUserIsLoggedIn: action((state, payload) => {
    state.userIsLoggedIn = payload;
  }),
  setAcumaticaIsLoggedIn: action((state, payload) => {
    state.acumaticaIsLoggedIn = payload;
  }),
  setAcumaticaLogin: action((state, payload) => {
    state.acumaticaLogin = payload;
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  resetUserAction: action((state, payload) => {
    state.user = {};
  }),
  setACMUser: action((state, payload) => {
    state.acmUser = payload;
  }),
  setACMPassword: action((state, payload) => {
    state.acmPassword = payload;
  }),
  setLogoutHeaders: action((state, payload) => {
    // take the payload[3] string and pull the .ASPXAUTHToken and .ASPXAUTH cookies out of it
    // then set the state.logoutHeaders to an array of objects with the cookies
    const cookies = payload[3].split(";");
    const aspxauth = cookies[0].split("=");
    state.logoutHeaders = aspxauth[1];
  }),
  setEmail: action((state, payload) => {
    state.email = payload;
  }),
  setPassword: action((state, payload) => {
    state.password = payload;
  }),
  setLoginLoading: action((state, payload) => {
    state.loginLoading = payload;
  }),
};
export default loginActions;
