import React from "react";
import { TouchableOpacity, View } from "react-native";
import Card from "../../presentational/Card";
import OperationsList from "../List/OperationsList";
import DynamicText from "../../presentational/MyText";
import { useStoreActions } from "easy-peasy";

const OperationsCard = () => {
	const { setOperationListModalVisibleAction } = useStoreActions(
		(actions) => actions
	);
	return (
		<TouchableOpacity
			onPress={() => {
				setOperationListModalVisibleAction(true);
			}}
		>
			<View
				style={{
					...styles.cardView,
					flexDirection: "column",
				}}
			>
				<DynamicText text={"Operations"} fontSize={12} />
				<View style={{ padding: "5px" }} />
				<Card>
					<OperationsList />
				</Card>
			</View>
		</TouchableOpacity>
	);
};

export default OperationsCard;

const styles = {
	cardView: {
		alignSelf: "center",
		alignContent: "flex-start",

		width: "92%",
	},
};
