import workCenterModel from './model'
import workCenterthunks from './thunks';
import workCenterActions from './actions'
const workCenterStore = {
   ...workCenterModel,
   ...workCenterthunks,
   ...workCenterActions
}


export default workCenterStore;