import moment from "moment/moment";

const workCenterModel = {
  workCenters: [],
  workCenterDispatch: [],
  selectedDispatch: [],
  operations: [],

  currentDispatchObject: {},
  selectedOperation: {},
  dispatchLoading: false,
  operationsLoading: false,
  modalLoading: false,
  moveActive: false,
  employees: [],
  opNbr: "",

  materialActive: true,
  stepsActive: false,
  toolsActive: false,

  workCenterString: "",

  operationListModalVisible: false,
  materialListModalVisible: false,
  dispatchListModalVisible: false,

  productionOrderSearchInput: "",
  operationSearchInput: "",
};

export default workCenterModel;
