import React from "react";
import { Overlay } from "@rneui/themed";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Text from "./Text";
import { Ionicons } from "@expo/vector-icons";

const CustomModal = ({ isVisible, title, children, onClose, ...props }) => {
	return (
		<Overlay
			isVisible={isVisible}
			onBackdropPress={onClose}
			overlayStyle={styles.overlay}
		>
			<View style={styles.container} {...props}>
				<View style={styles.titleContainer}>
					<Text style={styles.title}>{title}</Text>
					<TouchableOpacity onPress={onClose}>
						<Ionicons name="close" size={30} color="#00205b" />
					</TouchableOpacity>
				</View>
				<View style={styles.content}>{children}</View>
			</View>
		</Overlay>
	);
};

const styles = StyleSheet.create({
	overlay: {
		borderRadius: 10,
	},
	container: {
		flex: 1,
	},
	titleContainer: {
		flexDirection: "row",
		// justifyContent: "flex-start",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottomWidth: 1,
		borderColor: "#ccc",
		paddingBottom: 10,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
	content: {
		marginTop: 10,
	},
});

export default CustomModal;
