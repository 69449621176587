import model from './model';
import thunks from './thunks';
import actions from './actions';
const store = {
  ...model,
  ...thunks,
  ...actions,
};

export default store;
