//
import React, { useEffect } from "react";
import { Platform } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as SecureStore from "expo-secure-store";
import { useStoreActions, useStoreState } from "easy-peasy";
import AcumaticaLogin from "../components/screens/AcumaticaLogin.js";
import Login from "../components/screens/Login.js";
import Home from "../components/screens/Home.js";
import Docs from "../components/screens/Docs.js";
import Viewer from "../components/screens/DocViewer.js";

const Stack = createStackNavigator();

const AppNavigator = () => {
	const { userIsLoggedIn, acumaticaIsLoggedIn, email, password } =
		useStoreState((state) => state);
	const { authenticate } = useStoreActions((actions) => actions);

	useEffect(() => {
		const checkAuth = async () => {
			if (Platform.OS !== "web") {
				const erpAuthenticated = await SecureStore.getItemAsync(
					"erpAuthenticated"
				);
				if (erpAuthenticated === "true") {
					const payload = {
						email,
						password,
					};
					await authenticate(payload);
				}
			}
		};
		checkAuth();
	}, []);

	return (
		<NavigationContainer>
			<Stack.Navigator>
				{!userIsLoggedIn && !acumaticaIsLoggedIn ? (
					<Stack.Screen
						name="Login"
						component={Login}
						options={{
							headerShown: false,
						}}
					/>
				) : userIsLoggedIn && !acumaticaIsLoggedIn ? (
					<Stack.Screen
						name="MES Login"
						component={AcumaticaLogin}
						options={{
							headerShown: false,
						}}
					/>
				) : userIsLoggedIn && acumaticaIsLoggedIn ? (
					<>
						<Stack.Screen
							name="CFBS MES"
							component={Home}
							options={{
								title: "CFBS Shop Floor Pro",
								headerShown: false,
							}}
						/>
						<Stack.Screen name="Docs" component={Docs} />
						<Stack.Screen name="Viewer" component={Viewer} />
					</>
				) : (
					<Stack.Screen
						name="MES Login"
						component={AcumaticaLogin}
						options={{
							headerShown: false,
						}}
					/>
				)}
			</Stack.Navigator>
		</NavigationContainer>
	);
};
export default AppNavigator;
