import { thunk } from "easy-peasy";
import {
  CreateMoveAPICall,
  SelectDispatchAPICall,
  SelectWorkCenterAPICall,
} from "../../services";

const thunks = {
  createMoveThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);
    try {
      await CreateMoveAPICall({
        ...helpers.getState().move.input,
        Details: [
          {
            ...helpers.getState().move.input.Details[0],
            ProductionNbr: {
              value: helpers.getState().selectedOperation.ProductionNbr,
            },
            InventoryID: {
              value: helpers.getState().selectedOperation.InventoryID,
            },
            OperationNbr: {
              value: helpers.getState().selectedOperation.OperationID,
            },
          },
        ],
      });

      let wc = await SelectWorkCenterAPICall(
        helpers.getState().desiredWorkCenter
      );
      await actions.setSelectedWorkCenter(wc.data.data);
      actions.setOpNbr("");
      actions.setMoveModalVisible(false);
      actions.setAlertThunk({
        type: "success",
        message: "Move Created Successfully!",
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.data);
      actions.setAlertThunk({
        type: "error",
        message: error.response.data.data,
      });
    }
    actions.setLoadingAction(false);
  }),
};
export default thunks;
