import React from 'react';
import { Image } from 'react-native';
import { useStoreState } from 'easy-peasy';
import PDFReader from 'rn-pdf-reader-js';

const Viewer = () => {
  const { webViewURL, webViewActive } = useStoreState((state) => state);

  return (
    <>
      {webViewActive && webViewURL.includes('JVBERi0') ? (
        <PDFReader
          source={{ base64: webViewURL }}
          withScroll={true}
          withPinchZoom={true}
          onError={(error) => console.log(error)}
        />
      ) : (
        <Image
          source={{ uri: webViewURL }}
          style={{ width: '100%', height: '100%' }}
        />
      )}
    </>
  );
};
export default Viewer;
