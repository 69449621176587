import React from "react";
import CustomModal from "../../presentational/Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import { height, width } from "../../../utilities/general";
import MaterialList from "../List/MaterialList";

const MaterialListModal = () => {
  const { materialListModalVisible } = useStoreState((state) => state);

  const { setMaterialListModalVisibleAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <CustomModal
      isVisible={materialListModalVisible}
      onClose={() => setMaterialListModalVisibleAction(false)}
      title="Material"
      style={{ width: width * 0.9, height: height * 0.9 }}
      width="50%"
      height="50%"
    >
      <MaterialList />
    </CustomModal>
  );
};

export default MaterialListModal;
