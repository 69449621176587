import { useEffect, useState, useRef } from "react";
import { AntDesign } from "@expo/vector-icons";
import Table from "./Table";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import { StyleSheet } from "react-native";
import { ScrollView } from "react-native";
import { height } from "../../utilities/general";
import Text from "./Text";
import { useStoreActions, useStoreState } from "easy-peasy";

function List({
  tableHead = [],
  tableRows = [],
  striped = true,
  hover = true,
  border = true,
  pageSize = 10,
  loading = false,
  selectItem = -1,
  listHeight = 6.5,
  maxHeight = 5.5,
  pagination = false,
  doNotScroll = true,
  fontSize = 14,
  isShownInModal,
  ...props
}) {
  const { selectedItemIndex } = useStoreState((state) => state.labor);
  const { setSelectedItemIndex } = useStoreActions((actions) => actions);
  const scrollViewRef = useRef();
  // const itemHeight = 60;

  const [itemHeight, setItemHeight] = useState(60);

  useEffect(() => {
    const position = selectedItemIndex * itemHeight;
    if (scrollViewRef.current && selectedItemIndex !== null) {
      scrollViewRef.current.scrollTo({ y: position, animated: false });
    }
  }, [selectedItemIndex, tableRows]);

  const handleSelectItem = (children, index) => {
    if (props.setSelectedItemIndex !== undefined) {
      props.setSelectedItemIndex(index);
    } else {
      setSelectedItemIndex(index);
    }
    props.onPress(children, index);
  };

  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(tableRows.length / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedRows = tableRows.slice(startIndex, endIndex);
  if (pagination == true) {
    tableRows = paginatedRows;
  }

  const handleLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setItemHeight(height);
  };

  return (
    <>
      {loading ? (
        <ScrollView contentContainerStyle={styles.tbodyScrollable1}>
          <ActivityIndicator
            style={{
              alignSelf: "center",
            }}
            size={35}
          />
        </ScrollView>
      ) : (
        <>
          <Table {...props}>
            <Table.thead>
              <Table.tr>
                {tableHead.map((children, index) => (
                  <Table.th
                    key={index}
                    border={border}
                    index={index}
                    width={
                      props.width
                        ? props.width[index]
                        : tableHead.length
                        ? `${100 / tableHead.length}%`
                        : "auto"
                    }
                  >
                    {children}
                  </Table.th>
                ))}
              </Table.tr>
            </Table.thead>
          </Table>
          <ScrollView
            ref={scrollViewRef}
            style={
              isShownInModal
                ? {}
                : {
                    width: "100%",
                    height: height / listHeight,
                    maxHeight: height / maxHeight, // Adjust this value to your needs
                    overflow: "auto",
                  }
            }
            contentContainerStyle={{ width: "100%" }}
            showsVerticalScrollIndicator={false}
          >
            <Table {...props}>
              <Table.tbody>
                {tableRows.map((row, rindex) => (
                  <Table.tr
                    key={rindex}
                    striped={striped && rindex % 2 === 0}
                    hover={hover}
                  >
                    {row.map((children, index) => (
                      <Table.td
                        onLayout={handleLayout}
                        onPress={() => {
                          if (doNotScroll) {
                            props.onPress(children, index);
                          } else {
                            handleSelectItem(children, rindex);
                          }
                        }}
                        key={index}
                        border={border}
                        index={index}
                        fontSize={fontSize}
                        width={
                          props.width
                            ? props.width[index]
                            : tableHead.length
                            ? `${100 / tableHead.length}%`
                            : "auto"
                        }
                        backgroundColor={
                          rindex % 2 === 0 && selectItem !== rindex
                            ? "#fff"
                            : selectItem == rindex
                            ? "#D0E1FC"
                            : "#F7F7F7"
                        }
                        color={
                          children === "Inactive" ||
                          children === "CO" ||
                          children === "Unavailable" ||
                          children == "Broken Tool" ||
                          children == "Unserviceable" ||
                          children == "Checked Out"
                            ? "red"
                            : children === "Active" ||
                              children === "Available" ||
                              children === "Configured"
                            ? "green"
                            : children === "Reserved" ||
                              children === "Inspection"
                            ? "#c0b000"
                            : "#00205b"
                        }
                      >
                        {children}
                      </Table.td>
                    ))}
                  </Table.tr>
                ))}
              </Table.tbody>
            </Table>
          </ScrollView>
        </>
      )}
      {pagination && (
        <View style={styles.paginationContainer}>
          <TouchableOpacity
            onPress={handlePrevPage}
            disabled={currentPage === 0}
          >
            <AntDesign
              name="left"
              size={20}
              color={currentPage === 0 ? "gray" : "black"}
            />
          </TouchableOpacity>
          <Text style={styles.pageInfo}>
            Page {currentPage + 1} of {totalPages}
          </Text>
          <TouchableOpacity
            onPress={handleNextPage}
            disabled={currentPage >= totalPages - 1}
          >
            <AntDesign
              name="right"
              size={20}
              color={currentPage >= totalPages - 1 ? "gray" : "black"}
            />
          </TouchableOpacity>
        </View>
      )}
    </>
  );
}

export default List;

const styles = StyleSheet.create({
  tbodyScrollable: {
    width: "100%",
    height: height / 6.5,
    maxHeight: height / 5.5, // Adjust this value to your needs
    overflow: "auto",
  },

  tbodyScrollable1: {
    width: "100%",
    height: 180,
    maxHeight: 180, // Adjust this value to your needs
    overflow: "auto",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 10,
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  pageInfo: {
    marginHorizontal: 10,
  },
});
