import { SearchBar as RNSearchBar } from '@rneui/themed';
import styled from 'styled-components/native';
import Icon from './Icon';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import { Platform } from 'react-native';

const SSearchBar = styled(RNSearchBar)`
  padding: 0 16px;
`;

function SearchBar(props) {
  return (
    <SSearchBar
      platform={`${Platform.OS === 'ios' ? 'ios' : 'default'}`}
      containerStyle={{
        backgroundColor: 'white',
        border: 0,
        boxShadow: '0px 0px 10px grey',
      }}
      inputContainerStyle={{ backgroundColor: '#F7F7F7' }}
      searchIcon={<Icon Component={Entypo} name="magnifying-glass" />}
      clearIcon={<Icon Component={MaterialCommunityIcons} name="clear" />}
      {...props}
    />
  );
}

export default SearchBar;
