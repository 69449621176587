import { Platform } from "react-native";
import * as SecureStore from "expo-secure-store";

const webPlatform = "web";

class Storage {
  constructor() {}

  static async getItem(key) {
    return Platform.OS === webPlatform
      ? localStorage.getItem(key)
      : await SecureStore.getItemAsync(key);
  }

  static async setItem(key, value) {
    Platform.OS === webPlatform
      ? localStorage.setItem(key, value)
      : await SecureStore.setItemAsync(key, value);
  }

  static async removeItem(key) {
    Platform.OS === webPlatform
      ? localStorage.removeItem(key)
      : await SecureStore.deleteItemAsync(key);
  }
}

export default Storage;
