import React from 'react';
import { Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import CalendarPicker from 'react-native-calendar-picker';

import CustomModal from '../../presentational/Modal';

const { width } = Dimensions.get('window');

export default function TaskDatePicker() {
  const { datePickerVisible } = useStoreState((state) => state.task);

  const { setDatePickerVisible, setTaskInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <CustomModal
      style={{ width: 500 }}
      isVisible={datePickerVisible}
      onClose={() => setDatePickerVisible(false)}
      height="60%"
      width="35%"
      title="Due Date: "
      children={
        <CalendarPicker
          nextComponent={
            <Ionicons
              name="ios-arrow-forward"
              size={20}
              color="black"
              style={{ right: width / 2.25, position: 'absolute' }}
            />
          }
          previousComponent={
            <Ionicons
              name="ios-arrow-back"
              size={20}
              color="black"
              style={{ left: width / 2.25, position: 'absolute' }}
            />
          }
          startFromMonday={true}
          minDate={new Date(2018, 1, 1)}
          maxDate={new Date(2050, 6, 3)}
          weekdays={['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']}
          months={[
            'January',
            'Febraury',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ]}
          todayBackgroundColor="#e6ffe6"
          selectedDayColor="#66ff33"
          selectedDayTextColor="#000000"
          scaleFactor={1000}
          textStyle={{
            color: '#000000',
            zIndex: 1000,
          }}
          onDateChange={(date) => {
            setTaskInputAction({
              DueDate: { value: moment(date).format('MM/DD/YYYY') },
            });
            setDatePickerVisible(false);
          }}
        />
      }
    />
  );
}
