import moment from 'moment';

const defaultInputState = {
  Body: {
    value: '',
  },
  CompletionPercentage: {
    value: 0,
  },
  DueDate: {
    value: new Date(),
  },
  Internal: {
    value: true,
  },
  Owner: {
    value: '',
  },
  Priority: {
    value: '',
  },
  RelatedEntityDescription: {
    value: '',
  },
  RelatedEntityNoteID: {
    value: '',
  },
  RelatedEntityType: {
    value: 'PX.Objects.AM.AMProdItem',
  },
  StartDate: {
    value: moment().format(),
  },
  Status: {
    value: 'Open',
  },
  Summary: {
    value: '',
  },
};

const state = {
  task: {
    taskModalVisible: false,
    input: defaultInputState,
    taskSubject: '',
    datePickerVisible: false,
  },
};

export { state as default, defaultInputState };
