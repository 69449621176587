import styled from 'styled-components/native';

const Card1 = styled.View`
  background-color: white;
  width: 100%;
  box-shadow: 0px 0px 10px grey;
`;

const Card = ({ children, ...props }) => {
  return <Card1 {...props}>{children}</Card1>;
};
export default Card;
