import { action } from "easy-peasy";

const workCenterActions = {
  addWorkCenters: action((state, payload) => {
    state.workCenters = payload.map((workCenter) => {
      return {
        WorkCenter: workCenter.WorkCenter,
        Description: workCenter.Description,
      };
    });
  }),

  setSelectedWorkCenter: action((state, payload) => {
    state.operations = [];
    state.selectedOperation = {};
    state.workCenterDispatch = [];
    state.workCenterDispatch = payload;
  }),
  setWorkCenterDispatch: action((state, list) => {
    state.workCenterDispatch = list;
  }),
  setDesiredWorkCenter: action((state, payload) => {
    state.desiredWorkCenter = payload;
  }),

  setSelectedDispatch: action((state, payload) => {
    state.selectedDispatch = payload;
    state.selectedOperation = {};
    state.operations = [];
  }),

  setDispatchLoading: action((state, payload) => {
    state.dispatchLoading = payload;
  }),
  setOperations: action((state, list) => {
    state.operations = list.map((operation) => {
      let validCount = 0;
      let invalidCount = 0;

      operation &&
        operation.Material &&
        operation.Material.map((material) => {
          if (material.QtyRequired.value < material.QtyActual.value) {
            validCount++;
          } else {
            invalidCount++;
          }
        });

      if (validCount > 0 && invalidCount > 0) {
        operation.checkmark = true;
        state.moveActive = true;
      } else if (validCount > 0) {
        operation.checkmark = true;
        state.moveActive = true;
      } else {
        operation.checkmark = false;
        state.moveActive = false;
      }

      return operation;
    });
  }),

  setOperationsLoading: action((state, payload) => {
    state.operationsLoading = payload;
  }),

  selectOperation: action((state, payload) => {
    state.opNbr = payload;
    payload = payload.trim();
    payload = payload.replace(/\s/g, "");
    state.selectedOperation = state.workCenterDispatch.find(
      (item) =>
        item.OperationID === payload.toString() &&
        item.ProductionNbr === state.selectedDispatch
    );
  }),

  setModalLoading: action((state, payload) => {
    state.modalLoading = payload;
  }),

  setCurrentDispatchObject: action((state, payload) => {
    state.currentDispatchObject = payload;
  }),

  setEmployees: action((state, payload) => {
    state.employees = payload;
  }),

  setOpNbr: action((state, payload) => {
    state.opNbr = payload;
  }),

  setMaterialActiveAction: action((state, payload) => {
    state.stepsActive = false;
    state.toolsActive = false;
    state.materialActive = payload;
  }),
  setStepsActiveAction: action((state, payload) => {
    state.materialActive = false;
    state.toolsActive = false;
    state.stepsActive = payload;
  }),
  setToolsActiveAction: action((state, payload) => {
    state.materialActive = false;
    state.stepsActive = false;
    state.toolsActive = payload;
  }),

  setWorkCenterString: action((state, payload) => {
    state.workCenterString = payload;
  }),
  setDispatchListModalVisibleAction: action((state, payload) => {
    state.dispatchListModalVisible = payload;
  }),
  setOperationListModalVisibleAction: action((state, payload) => {
    state.operationListModalVisible = payload;
  }),
  setMaterialListModalVisibleAction: action((state, payload) => {
    state.materialListModalVisible = payload;
  }),

  validateOperation: action((state, payload) => {
    if (state.currentDispatchObject.OperationID === payload.trim()) {
      return;
    } else {
      const matchingDispatch = state.workCenterDispatch.find(
        (dispatch) => dispatch.OperationID === payload.trim()
      );
      state.currentDispatchObject = matchingDispatch;
    }
  }),

  setProductionOrderSearchInputAction: action((state, input) => {
    state.productionOrderSearchInput = input;
  }),
  setOperationSearchInputAction: action((state, input) => {
    state.operationSearchInput = input;
  }),
};

export default workCenterActions;
