import React from 'react';
import { Text, TouchableOpacity } from 'react-native';

const MyButton = ({
  icon,
  disabled,
  color,
  isSelected = false,
  isSelectedStyle,
  isNotSelectedStyle,
  title,
  textProps,
  ...buttonProps
}) => {
  return (
    <>
      {isSelected ? (
        <TouchableOpacity
          disabled={disabled}
          {...buttonProps}
          style={isSelectedStyle}
        >
          {icon ? icon : null}

          <Text {...textProps} style={{ ...textProps.style, color: color }}>
            {' '}
            {title}
          </Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          disabled={disabled}
          {...buttonProps}
          style={isNotSelectedStyle}
        >
          {icon ? icon : null}
          <Text {...textProps} style={{ ...textProps.style, color: color }}>
            {' '}
            {title}
          </Text>
        </TouchableOpacity>
      )}
    </>
  );
};

export default MyButton;
