import moment from "moment";
import List from "../../presentational/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Dimensions, TouchableOpacity, View } from "react-native";
import Text from "../../presentational/Text";

const { height } = Dimensions.get("window");

function ProductionOrderList(props) {
  const {
    workCenterDispatch,
    dispatchLoading,
    selectedDispatch,
    productionOrderSearchInput,
  } = useStoreState((state) => state);

  const { selectDispatchThunk, setCurrentDispatchObject } = useStoreActions(
    (actions) => actions
  );

  const Element = ({ data, dispatch }) => (
    <TouchableOpacity
      onPress={() => {
        setCurrentDispatchObject(dispatch);
        selectDispatchThunk(data);
      }}
    >
      <View>
        <Text style={styles.dispatchElementText}>{data}</Text>
      </View>
    </TouchableOpacity>
  );

  const productionOrderList = workCenterDispatch.reduce(
    (uniqueProductionOrders, item) => {
      const formattedSearchInput = productionOrderSearchInput
        .trim()
        .toLowerCase();
      const isNew = !uniqueProductionOrders.find(
        (wcd) => wcd.ProductionNbr === item.ProductionNbr
      );
      isNew &&
        (item.ProductionNbr.trim()
          .toLowerCase()
          .includes(formattedSearchInput) ||
          item.ProductionOrderDesc.trim()
            .toLowerCase()
            .includes(formattedSearchInput) ||
          item.InventoryID.trim()
            .toLowerCase()
            .includes(formattedSearchInput)) &&
        uniqueProductionOrders.push(item);
      return uniqueProductionOrders;
    },
    []
  );

  return (
    <List
      doNotScroll={false}
      onPress={(data) => {
        selectDispatchThunk(data.props.data);
        setCurrentDispatchObject(data.props.dispatch);
      }}
      selectItem={
        selectedDispatch == ""
          ? -1
          : productionOrderList.findIndex(
              (item) => item.ProductionNbr === selectedDispatch
            )
      }
      loading={dispatchLoading}
      tableHead={[
        "Production Number",
        "Description",
        "Inventory ID",
        "Inventory Description",
        "Status",
        "Qty to Produce",
        "Start Date",
        "End Date",
      ]}
      tableRows={productionOrderList.map((item) => [
        <Element data={item.ProductionNbr} dispatch={item} />,
        item.ProductionOrderDesc,
        item.InventoryID,
        item.InventoryDescription,
        item.Status,
        parseInt(item.QtytoProduce),
        moment(item.StartDate).format("MM/DD/YYYY"),
        moment(item.EndDate).format("MM/DD/YYYY"),
      ])}
      {...props}
    />
  );
}

export default ProductionOrderList;

const styles = {
  dispatchElementText: {
    fontSize: 25 - height / 150,
    textAlign: "center",
    alignSelf: "center",
    padding: 10,
    textDecorationLine: "underline",
    color: "blue",
  },
};
