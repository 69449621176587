import moment from "moment";

function roundToNearest15Minutes(time) {
  const momentTime = moment(time, "h:mm A");
  const minutes = momentTime.minutes();
  const remainder = minutes % 15;
  return momentTime
    .add(remainder < 8 ? -remainder : 15 - remainder, "minutes")
    .format("h:mm A");
}

function calculateNorthStarLaborTimeDifference(startTime, endTime) {
  const duration = moment.duration(
    moment(endTime, "h:mm A").diff(moment(startTime, "h:mm A"))
  );
  const roundedMinutes = Math.round(duration.asMinutes() / 15) * 15;
  return moment
    .utc(moment.duration(roundedMinutes, "minutes").asMilliseconds())
    .format("HH:mm");
}

export { roundToNearest15Minutes, calculateNorthStarLaborTimeDifference };
