import React from "react";
import { TouchableOpacity, View } from "react-native";
import Card from "../../presentational/Card";
import MaterialList from "../List/MaterialList";
import StepsList from "../List/StepsList";
import ToolsList from "../List/ToolsList";
import { useStoreActions, useStoreState } from "easy-peasy";
import MaterialButtonGroup from "../ButtonGroup/MaterialButtonGroup";
import Gap from "../../presentational/Gap";

const MaterialCard = () => {
	const { materialActive, toolsActive, stepsActive } = useStoreState(
		(state) => state
	);
	const { setMaterialListModalVisibleAction } = useStoreActions(
		(actions) => actions
	);

	return (
		<View
			style={{
				...styles.cardView,
				flexDirection: "column",
			}}
		>
			<MaterialButtonGroup />
			<View style={{ padding: "5px" }} />

			<TouchableOpacity
				onPress={() => {
					setMaterialListModalVisibleAction(true);
				}}
			>
				<Card>
					{toolsActive ? (
						<ToolsList />
					) : stepsActive ? (
						<StepsList />
					) : materialActive ? (
						<MaterialList />
					) : (
						<MaterialList />
					)}
				</Card>
			</TouchableOpacity>
		</View>
	);
};

export default MaterialCard;

const styles = {
	cardView: {
		alignSelf: "center",
		alignContent: "flex-start",

		width: "92%",
	},
};
