import moment from "moment/moment";

const defaultInputState = {
	ControlQty: {
		value: null,
	},
	Date: {
		value: moment().format("YYYY-MM-DD"),
	},
	Description: {
		value: "",
	},
	Details: [
		{
			INDocType: {
				value: `Receipt`,
			},
			InventoryID: {
				value: "",
			},
			OperationNbr: {
				value: "",
			},
			OrderType: {
				value: `WO`,
			},
			ProductionNbr: {
				value: "",
			},
			QtyisScrap: {
				value: false,
			},
			Shift: {
				value: "1",
			},
			Quantity: {
				value: 0,
			},
			QtyScrapped: {
				value: 0,
			},
			// UOM: {
			// 	value: `EACH`,
			// },
		},
	],
	Hold: {
		value: false,
	},
	PostPeriod: {
		value:
			moment().month() + 1 < 10
				? "0" +
				  parseInt(moment().month() + 1).toString() +
				  moment().year().toString()
				: parseInt(moment().month() + 1).toString() +
				  moment().year().toString(),
	},
	Status: {
		value: "Released",
	},
	TotalQty: {
		value: 0,
	},
};

const state = {
	move: { moveModalVisible: false, input: defaultInputState },
};

export { state as default, defaultInputState };
