import { action } from "easy-peasy";
import moment from "moment";
import { Platform } from "react-native";
import { defaultInputState } from "./model";

const actions = {
	setLaborModalVisible: action((state, payload) => {
		state.labor.laborModalVisible = payload;
	}),
	setLaborInputAction: action((state, input) => {
		state.labor.input = {
			...state.labor.input,
			...input,
		};
	}),

	setLaborHoursAction: action((state, payload) => {
		state.labor.laborHours = payload;
	}),
	setLaborMinutesAction: action((state, payload) => {
		state.labor.laborMinutes = payload;
	}),

	addLaborLineItemAction: action((state, payload) => {
		console.log(payload);
		const formattedPayload = {
			note: {
				value: payload.note.value || "",
			},
			LaborType: {
				value: payload.LaborType.value,
			},
			LaborCode: {
				value: "DIRLAB", //payload.LaborType.value === "Direct" ? "DIRLAB" : "INDIRLAB",
			},
			ProductionNbr: {
				value: state.selectedDispatch,
			},
			OperationNbr: {
				value: payload.OperationNbr.value,
			},
			EmployeeID: {
				value: payload.EmployeeID.value,
			},
			LaborTime: {
				value: state.labor.laborHours + ":" + state.labor.laborMinutes, //payload.LaborTime.value,
			},
			StartTime: {
				value: "",
			},
			EndTime: {
				value: "",
			},
			Shift: {
				value: "1",
			},
			INDocType: {
				value: "Receipt",
			},
			InventoryID: {
				value: state.currentDispatchObject.InventoryID,
			},
			OrderType: {
				value: "WO",
			},
			QtyisScrap: {
				value: false,
			},
			// UOM: {
			// 	value: "EACH",
			// },
		};

		state.labor.laborLineItems.push(formattedPayload);
	}),
	removeLaborLineItemAction: action((state, index) => {
		state.labor.laborLineItems.splice(index, 1);
	}),

	setLaborLineItemsAction: action((state, payload) => {
		state.labor.laborLineItems = payload;
		// Add a property to each item in the array called "key" with a value of the index of the item in the array
		state.labor.laborLineItems.forEach((item, index) => {
			item.newLaborTime = item.LaborTime;
			item.newEndTime = moment(item.EndTime).format("h:mm A");
			item.newStartTime = moment(item.StartTime).format("h:mm A");
			item.newNote = item.Note || "";
		});
	}),

	setNewLaborTimeAction: action((state, { index, input }) => {
		state.labor.laborLineItems[index].newLaborTime = input;
	}),

	setNewStartTimeAction: action((state, { index, input }) => {
		state.labor.laborLineItems[index].newStartTime = input;
	}),

	setNewEndTimeAction: action((state, { index, input }) => {
		state.labor.laborLineItems[index].newEndTime = input;
	}),
	setAreYouSureModalVisibleAction: action((state, payload) => {
		state.labor.areYouSureModalVisible = payload;
	}),

	setSelectedLaborIndexAction: action((state, payload) => {
		state.labor.selectedLaborIndex = payload;
	}),

	setLaborNoteModalVisibleAction: action((state, payload) => {
		state.labor.laborNoteModalVisible = payload;
	}),

	setNewLaborNoteModalInputAction: action((state, { index, input }) => {
		state.labor.laborLineItems[index].newNote = input;
	}),

	setLaborListModalVisibleAction: action((state, payload) => {
		state.labor.laborListModalVisible = payload;
	}),
	setSelectedItemIndex: action((state, payload) => {
		state.labor.selectedItemIndex = payload;
	}),
	setLaborDatePickerVisible: action((state, payload) => {
		state.labor.laborDatePickerVisible = payload;
	}),
};

export default actions;
