import styled from "styled-components/native";
import Tbody from "./Tbody";
import Td from "./Td";
import Th from "./Th";
import Tr from "./Tr";
import Thead from "./Thead";

const Table = styled.View`
	display: table;
	width: 100%;
`;

Table.thead = Thead;
Table.th = Th;
Table.tbody = Tbody;
Table.td = Td;
Table.tr = Tr;
export default Table;
