import { action } from 'easy-peasy';
import moment from 'moment';
import { Platform } from 'react-native';
import { defaultInputState } from './model';

const actions = {
  setTaskInputAction: action((state, input) => {
    state.task.input = {
      ...state.task.input,
      ...input,
    };
  }),
  resetTaskInputAction: action((state, input) => {
    state.task.input = defaultInputState;
  }),

  setTaskModalVisible: action((state, payload) => {
    state.task.taskModalVisible = payload;
  }),
  setTaskSubject: action((state, payload) => {
    state.task.taskSubject = payload;
  }),
  setDatePickerVisible: action((state, payload) => {
    state.task.datePickerVisible = payload;
  }),
};

export default actions;
