import { action } from 'easy-peasy';
import moment from 'moment';
import { Platform } from 'react-native';
import { defaultInputState } from './model';

const actions = {
  setMoveInputAction: action((state, input) => {
    state.move.input = {
      ...state.move.input,
      ...input,
    };
  }),

  setMoveModalVisible: action((state, payload) => {
    state.move.moveModalVisible = payload;
  }),
};

export default actions;
