import List from "../../presentational/List";
import { useStoreState } from "easy-peasy";

function ToolsList(props) {
  const { workCenterDispatch, selectedOperation } = useStoreState(
    (state) => state
  );

  return (
    <List
      tableHead={["ToolID", "Description", "Qty Required", "Total Actual Uses"]}
      tableRows={workCenterDispatch
        .filter((wcd) => wcd.OperationID === selectedOperation?.OperationID)
        .map((item) => {
          return [
            item.ToolID,
            item.ToolDesc,
            item.ToolQtyReq,
            item.ToolTotalActUses,
          ];
        })}
      {...props}
    />
  );
}
export default ToolsList;
