import { thunk } from "easy-peasy";
import {
  GetEmployeesAPICall,
  GetWorkCentersAPICall,
  SelectDispatchAPICall,
  SelectWorkCenterAPICall,
} from "../../services";

const workCenterThunks = {
  getWorkCentersThunk: thunk(async (actions, payload, helpers) => {
    try {
      const response = await GetWorkCentersAPICall();
      await actions.addWorkCenters(response.data.data);
    } catch (error) {
      actions.setAlertThunk({
        type: "error",
        message: "Error getting work centers",
      });
    }
  }),

  selectWorkCenterThunk: thunk(async (actions, payload, helpers) => {
    actions.setDispatchLoading(true);
    try {
      const [chosenWorkCenterResponse, operationsResponse] = await Promise.all([
        SelectWorkCenterAPICall({
          WorkCenter: payload,
        }),
        SelectDispatchAPICall({
          ProductionNbr: helpers.getState().selectedDispatch,
        }),
      ]);
      actions.setSelectedWorkCenter(chosenWorkCenterResponse.data.data);
      actions.setOperations(
        operationsResponse.data.data
          .filter((item) => item.Operations[0])
          .map((item) => ({
            ...item.Operations[0],
            ProductionNbr: item.ProductionNbr,
          }))
      );
    } catch (error) {
      actions.setAlertThunk({
        type: "error",
        message: "Error getting work centers",
      });
    }
    actions.setDispatchLoading(false);
  }),

  selectDispatchThunk: thunk(async (actions, payload, helpers) => {
    actions.setOperationsLoading(true);
    try {
      actions.setOpNbr("");
      actions.setSelectedDispatch(payload);
    } catch (error) {
      actions.setAlertThunk({
        type: "error",
        message: "Error getting work centers",
      });
    }
    actions.setOperationsLoading(false);
  }),

  getEmployeesThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await GetEmployeesAPICall();
      actions.setEmployees(response.data.data);
    } catch (error) {
      actions.setAlertThunk({
        type: "error",
        message: "Error getting employees",
      });
    }
    actions.setLoadingAction(false);
  }),
};
export default workCenterThunks;
