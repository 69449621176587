import state from './model';
import thunks from './thunks';
import actions from './actions';

const store = {
  ...state,
  ...thunks,
  ...actions,
};

export default store;
