import { Dimensions, PixelRatio } from "react-native";

function pxToDp(pxValue) {
  return PixelRatio.roundToNearestPixel(pxValue / PixelRatio.get());
}

function remToDp(remValue, baseFontSize = 16) {
  const dpValue = remValue * PixelRatio.getFontScale() * baseFontSize;
  return PixelRatio.roundToNearestPixel(dpValue);
}

function vwToDp(vwValue) {
  const dpValue = (vwValue * Dimensions.get("window").width) / 100;
  return PixelRatio.roundToNearestPixel(dpValue);
}

function vhToDp(vhValue) {
  const dpValue = (vhValue * Dimensions.get("window").height) / 100;
  return PixelRatio.roundToNearestPixel(dpValue);
}

export { remToDp, vwToDp, vhToDp, pxToDp };
